<template class="text_google">
    <v-app>
      <v-system-bar
        class="noprint"
        color="grey darken-2"
        dark
        height="40"
        app
        lights-out
      >
        <v-toolbar-items class="ml-5">
          <v-btn
            href="javascript:window.print()"
            class="title elevation-0 mr-2"
            color="grey darken-1"
            x-large
          >
            <v-icon>mdi-printer</v-icon>&nbsp; พิมพ์
          </v-btn>
          <v-btn
            @click.stop="Export2Doc('exportContent', 'word-content')"
            class="title elevation-0"
            color="grey darken-1"
            x-large
          >
            <v-icon>mdi-file-word</v-icon>&nbsp; Export to Word
          </v-btn>
        </v-toolbar-items>
      </v-system-bar>
      <div id="exportContent" style="mso-page-orientation: landscape;">
        <div class="page">
    
  
          <v-simple-table :loading="loading">
            <thead>
              <tr>
                <td rowspan="2" width="5%" class="text-center green lighten-3 white--text ">
                  <span class="bold16">ที่</span>
                </td>
                <td rowspan="2" width="20%" class="text-center green lighten-3 white--text">
                  <span class="bold16">วิทยาลัย</span>
                </td>
                <td rowspan="2" width="10%" class="text-center green lighten-3 white--text">
                  <span class="bold16"> ประเภท</span>
                </td>
                <td width="30%" colspan="3" class="text-center green lighten-3 white--text">
                  <span class="bold16"> ปกติ</span>
                </td>
                <td width="15%" colspan="2" class="text-center green lighten-3 white--text">
                  <span class="bold16">ระยสั้น</span>
                </td>              
              </tr>
              <tr>
                <td>
                    ปีการศึกษา 2565
                </td>
                <td>
                    ปีการศึกษา 2566
                </td>
                <td>
                    ปีการศึกษา 2567
                </td>

                <td>
                    ปีการศึกษา 2566
                </td>
                <td>
                    ปีการศึกษา 2567
                </td>
              </tr>
            </thead>
            <tbody>      
              <tr
                v-for="(item, index) in rate_work_std02_query"
                :key="item.index"
              >
                <td class="text-center">
                  <span class="regular16">{{ index + 1 }}</span>
                </td>
                <td class="">
                  <span class="regular16">{{ item.user_firstname }}</span>
                </td>
                <td class="">
                  <span class="regular16">{{ item.manPower }}</span>
                </td>
                <td>
                  <span class="regular16">{{ calSum(item.ch165,item.ch265,item.ch365,item.sh165,item.sh265) }}</span>
                </td>
                <td class="text-center">
                  <span class="regular16">{{ calSum(item.ch166,item.ch266,item.ch366,item.sh166,item.sh266) }}</span>
                </td>

  
                <td class="text-center">
                  <span class="regular16">{{ calSum(item.ch167,item.ch267,item.ch367,item.sh167,item.sh267) }}</span>
                </td>

                <td class="text-center">
                  <span class="regular16">{{ item.sc66 }}</span>
                </td>

                <td class="text-center">
                  <span class="regular16">{{ item.sc67 }}</span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>  
        </div>
      </div>
    </v-app>
  </template>
  
  <script>
  export default {
    name: "App",
    data: () => ({
      ApiKey: "HRvec2021",
      rate_work_std02_query: [],   
      index: 1,
      loading:false,
    }),
  
    async mounted() {
      await this.rate_work_std02QueryAll();   
    },
  
    methods: {

      calSum(a, b, c, d, e) {
      let aa = parseInt(a) || 0;
      let bb = parseInt(b) || 0;
      let cc = parseInt(c) || 0;
      let dd = parseInt(d) || 0;
      let ee = parseInt(e) || 0;
      return aa + bb + cc + dd + ee;
    },
      async rate_work_std02QueryAll() {
        this.loading = true
        let result = await this.$http.post("rate_work_std02.php", {
          ApiKey: this.ApiKey,    
        }).finally(() => (this.loading=false))
        this.rate_work_std02_query = result.data;
      },
  
      Export2Doc(element, filename) {
        var preHtml =
          "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
        var postHtml = "</body></html>";
        var html =
          preHtml + document.getElementById(element).innerHTML + postHtml;
  
        var blob = new Blob(["\ufeff", html], {
          type: "application/msword"
        });
  
        // Specify link url
        var url =
          "data:application/vnd.ms-word;charset=utf-8," +
          encodeURIComponent(html);
  
        // Specify file name
        filename = filename ? filename + ".doc" : "document.doc";
  
        // Create download link element
        var downloadLink = document.createElement("a");
  
        document.body.appendChild(downloadLink);
  
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          // Create a link to the file
          downloadLink.href = url;
  
          // Setting the file name
          downloadLink.download = filename;
  
          //triggering the function
          downloadLink.click();
        }
        document.body.removeChild(downloadLink);
      }
    },
    props: {
      source: String
    },
  

  };
  </script>
  <style>
  @import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@100&display=swap");
  
  body {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16px;
    font-weight: 250;
  }
  
  u {
    border-bottom: 1px dotted #000;
    text-decoration: none;
  }
  
  * {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  
  .table,
  .th,
  .td {
    border-bottom: 0.5px solid black;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-collapse: collapse;
  }
  
  .text_j {
    border-collapse: collapse;
    text-align: justify;
    text-justify: inter-word;
    line-height: 1;
  }
  
  tfoot tr:hover {
    background-color: initial;
  }
  
  tbody tr:hover {
    background-color: inherit;
  }
  
  td,
  th {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
  }
  
  tfoot td {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-weight: bold;
  }
  
  #verticaltext {
    writing-mode: tb-rl;
    filter: flipv fliph;
    -webkit-transform: rotate(-90deg);
    white-space: nowrap;
    display: block;
    overflow: hidden;
    padding: 0;
  }
  
  .page {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    width: 21cm;
    min-height: 29.7cm;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-top: 0cm;
    margin-right: auto;
    margin-bottom: 0cm;
    margin-left: auto;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1cm;
  }
  
  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
    line-height: 1;
  }
  
  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }
  
  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }
  
  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }
  
  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
    font-weight: bold;
  }
  
  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 12pt;
  }
  
  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }
  
  .largerCheckbox {
    width: 30px;
    height: 30px;
    color: blue;
  }
  .td_line {
    border-bottom: 1pt dotted black;
  }
  /*.subpage {
         height: 247mm;
       }*/
  
  .BlackBold10 {
    font-weight: bold;
  }
  
  #content {
    display: table;
  }
  
  #pageFooter {
    display: table-footer-group;
  }
  
  #pageFooter:after {
    counter-increment: page;
    content: counter(page);
  }
  
  @media print {
    .page {
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      padding-top: 1cm;
      padding-right: 1cm;
      padding-bottom: 1cm;
      padding-left: 1.5cm;
    }
  
    .head {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 18pt;
      font-weight: bold;
    }
  
    .regular12 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
    }
  
    .regular16 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
    }
  
    .bold16 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
      font-weight: bold;
    }
  
    .blod12 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 16pt;
      font-weight: bold;
    }
  
    .blackRegula10 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 14pt;
    }
  
    .blackBold18 {
      font-family: "TH SarabunIT๙", "TH SarabunPSK";
      font-size: 18pt;
      font-weight: bold;
    }
  
    /* .head {
           font-size: 18px;
           font-weight: bold;
         }
         .regular12 {
           font-size: 12px;
         }
         .blod12 {
           font-size: 12px;
           font-weight: bold;
         }
         .blackRegula8 {
           font-size: 8px;
         } */
    .noprint {
      display: none;
    }
  }
  </style>
  